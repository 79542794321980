<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <!-- <li class="breadcrumb-item active">Locations</li> -->
                        </ol>
                    </div>
                    <h4 class="page-title">Training History</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex mb-4">
                            <label class="col-form-label me-2">Module</label>
                            <div style="width: 200px;">
                                <v-select v-model="module_id" label="name" :options="modules" :reduce="(name) => name.id"
                                    @option:selected="getFilterModule(module_id)" :clearable="false">
                                </v-select>
                            </div>
                            <div @click="refreshData" class="icon-css">
                                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                            </div>
                        </div>

                        <place-holder v-if="loading"></place-holder>

                        <div class="table-responsive" v-show="!loading">
                            <table class="table table-striped dt-responsive w-100 mb-3" id="history-datatable"
                                v-show="!loading">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Employee Name</th>
                                        <th>Position Level</th>
                                        <th>Department</th>
                                        <th>BU/BR/DIV</th>
                                        <th class="hideColumn">Module Id</th>
                                        <th>Module</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th v-if="hasPermissions(['trainer-edit'])">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in filterData" :key="index">
                                        <td>{{ ++index }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.position_name }}</td>
                                        <td>{{ item.department_name }}</td>
                                        <td>{{ item.bu_name }}</td>
                                        <td class="hideColumn">{{ item.module_id }}</td>
                                        <td>{{ item.module_name }}</td>
                                        <td>{{ item.date }}</td>
                                        <td>
                                            <!-- <button class="btn w-sm btn-success waves-effect waves-light me-5 w-75"
                                                v-if="item.status == 1">Complete</button>
                                            <button class="btn w-sm btn-secondary me-5 w-75" v-else>Expired</button> -->
                                            <badgeSuccess v-if="item.status == 1" :name="'Complete'" />
                                            <badgeWarning v-else :name="'Expired'" />
                                        </td>
                                        <td v-if="hasPermissions(['trainer-edit'])">
                                            <span class="action-icon" @click="showModal(history_data = item)"
                                                style="cursor: pointer;">
                                                <i class="mdi mdi-square-edit-outline"></i>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
    </div>
    <EnrollModal :modalId="'confirm-modal'">
        <template v-slot:header> Change for Status </template>
        <template v-slot:body>
            <div class="text-center" v-if="!loading">
            </div>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn w-sm btn-secondary me-4" data-bs-dismiss="modal">
                Close
            </button>
            <button :disabled="isLoading ? true : false" type="button" class="btn w-sm btn-success waves-effect waves-light"
                @click="changeExpiredStatus(history_data.id, 0)">
                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ isLoading == true ? 'Loading...' : 'Expired' }}
            </button>
        </template>
    </EnrollModal>
</template>
  
<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
import badgeWarning from "../../../components/shared/badgeWarning.vue";
import EnrollModal from "../../../components/shared/Modal.vue";

//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
    components: {
        vSelect,
        EnrollModal,
        badgeSuccess,
        badgeWarning,
    },
    mixins: [userHasPermissions],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            training_history: [],
            filterData: [],
            modules: [],
            module_id: "",
            history_data: "",
            employees: this.$store.getters["odoo/getAllEmployees"],
            loading: false,
            isLoading: false,
            baseUrl: process.env.VUE_APP_BASE_URL,
        };
    },
    methods: {
        async getAllModules() {
            this.loading = true;
            this.$Progress.start();
            await axios.get(`${this.baseUrl}admin/v1/modules`).then((response) => {
                this.modules = response.data.data;
                this.loading = false;
                this.$Progress.finish();
            });
        },
        async getAllTrainingHistory() {
            this.loading = true;
            this.$Progress.start();
            await axios
                .get(`${this.baseUrl}admin/v1/training-history`)
                .then((response) => {
                    this.training_history = response.data.data;
                    this.training_history.map((item) => {
                        this.employees.map((employee) => {
                            if (employee.emp_id == item.employee_id) {
                                let data = {
                                    id: item.id,
                                    emp_id: employee.emp_id,
                                    name: employee.name,
                                    position_name: employee.position_level[0].name,
                                    department_name: employee.department[0].name,
                                    bu_name: employee.business_unit[0].name,
                                    module_id: item.module_id,
                                    module_name: item.module_name,
                                    date: item.date,
                                    status: item.status
                                };
                                this.filterData.push(data);
                            }
                        });
                    })
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.toast.error("Something Went Wrong!");
                });
            $("#history-datatable").DataTable();
            this.clearFilter();
        },
        showModal(val) {
            console.log(val);
            $("#confirm-modal").modal("show");
        },
        async changeExpiredStatus(id, status) {
            this.isLoading = true;
            axios
                .post(
                    `${this.baseUrl}admin/v1/training-history/change-status/${id}`, {
                    status: status
                })
                .then(() => {
                    $("#confirm-modal").modal("hide");
                    this.getAllTrainingHistory();
                    this.toast.success("Successfully Status Changed!");
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.toast.error("Something Went Wrong!");
                    this.isLoading = false;
                });
        },
        getFilterModule(id) {
            this.clearFilter();
            $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
                console.log(settings, dataIndex);
                let moduleFilter = id;
                let moduleStage = data[5];
                if (moduleFilter == moduleStage) {
                    return true;
                }
                return false;
            });
            $("#history-datatable").DataTable().draw();
        },
        refreshData() {
            this.module_id = "";
            this.filterData = [];
            this.getAllModules();
            this.getAllTrainingHistory();
        },
        clearFilter() {
            $.fn.dataTable.ext.search.pop();
            $("#history-datatable").DataTable().draw();
        },
    },
    created() {
        this.clearFilter();
        this.getAllModules();
        this.getAllTrainingHistory();
    },
};
</script>
  
<style>
.hideColumn {
    display: none !important;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4;
}
</style>